import { ApiReturnType } from "./";

const leagueRoutes = {
    ADD_SPONSORED_LEAGUE: (): ApiReturnType => {
        return {
            url: `leagues/addSponsored`,
            method: "post",
        };
    },
    CHECK_LEAGUE_CODE: (): ApiReturnType => {
        return {
            url: `leagues/checkLeagueCode`,
            method: "post",
        };
    },
    COMPLETE_LEAGUE: (id: string): ApiReturnType => {
        return {
            url: `leagues/complete/${id}`,
            method: "post",
        };
    },
    CREATE_LEAGUE: (): ApiReturnType => {
        return {
            url: `leagues/create`,
            method: "post",
        };
    },
    DELETE_LEAGUE: (id: string): ApiReturnType => {
        return {
            url: `leagues/delete/${id}`,
            method: "post",
        };
    },
    DELETE_TEAM: (id: string): ApiReturnType => {
        return {
            url: `leagues/deleteteam/${id}`,
            method: "post",
        };
    },
    GET_ACTIVE_LEAGUES: (): ApiReturnType => {
        return {
            url: `leagues/type/active`,
            method: "get",
        };
    },
    GET_COMMISSIONERS: (id: string): ApiReturnType => {
        return {
            url: `leagues/commissioners/${id}`,
            method: "get",
        };
    },
    GET_COMPLETED_LEAGUES: (): ApiReturnType => {
        return {
            url: `leagues/type/completed`,
            method: "get",
        };
    },
    GET_CURRENT_LEAGUES: (): ApiReturnType => {
        return {
            url: `leagues/current`,
            method: "get",
        };
    },
    GET_LEAGUE: (id: string, showId?: string): ApiReturnType => {
        if (showId) {
            return {
                url: `leagues/${id}/${showId}`,
                method: "get",
            };
        } else {
            return {
                url: `leagues/${id}`,
                method: "get",
            };
        }
    },
    GET_LEAGUE_ACTIVITY: (
        id: string,
        showId?: string | number
    ): ApiReturnType => {
        if (showId) {
            return {
                url: `leagues/activity/${id}/${showId}`,
                method: "get",
            };
        } else {
            return {
                url: `leagues/activity/${id}`,
                method: "get",
            };
        }
    },
    GET_LEAGUE_SCORING_ACTIVITY: (id: string): ApiReturnType => {
        return {
            url: `leagues/activityScoringLog/${id}`,
            method: "get",
        };
    },
    JOIN_LEAGUE: (id: string, showId?: string): ApiReturnType => {
        if (showId) {
            return {
                url: `leagues/join/${id}/${showId}`,
                method: "post",
            };
        } else {
            return {
                url: `leagues/join/${id}`,
                method: "post",
            };
        }
    },
    LAST_DRAFT_ACTIVITY: (id: string): ApiReturnType => {
        return {
            url: `leagues/draft/last/${id}`,
            method: "get",
        };
    },
    RESTORE_LEAGUE: (id: string): ApiReturnType => {
        return {
            url: `leagues/restore/${id}`,
            method: "post",
        };
    },
    SAVE_COMMISSIONERS: (id: string): ApiReturnType => {
        return {
            url: `leagues/commissioners/save/${id}`,
            method: "post",
        };
    },
    SAVE_COMMISSIONER_NOTE: (id: string): ApiReturnType => {
        return {
            url: `leagues/saveCommissionerNote/${id}`,
            method: "post",
        };
    },
    SAVE_LEAGUE: (id: string): ApiReturnType => {
        return {
            url: `leagues/save/${id}`,
            method: "post",
        };
    },
    SEARCH_PUBLIC_LEAGUES: (): ApiReturnType => {
        return {
            url: `leagues/publicSearch`,
            method: "get",
        };
    },
    UNLOCK_LEAGUE: (id: string): ApiReturnType => {
        return {
            url: `leagues/unlock/${id}`,
            method: "post",
        };
    },
};

export default leagueRoutes;
