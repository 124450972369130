import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { toast } from "react-toastify";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { Avatar } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { imageUrl } from "../../config";
import { Commissioner, LeaguesLeagueUser } from "../../models/LeagueUsers";

import SubmitButton from "../atoms/SubmitButton";
import { ErrorText, ModalStyled } from "../atoms";

interface ManageCommissionersProps {
    leagueId: string | number;
    setShowModal: (show: boolean) => void;
    showModal: boolean;
}

const ManageCommissioners: React.FC<ManageCommissionersProps> = ({
    leagueId,
    showModal,
    setShowModal,
}) => {
    const [error, setError] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [commissioners, setCommissioners] = useState<number[]>([]);
    const [leagueUsers, setLeagueUsers] = useState<LeaguesLeagueUser[]>([]);

    const getCommissioners = useApi(
        apiRoutes.GET_COMMISSIONERS(leagueId.toString()),
        {
            responseKey: "commissioners",
            onSuccess: (response: Commissioner[]) => {
                const coms = response.map((com) => com.id);
                setCommissioners(coms);
            },
        }
    );

    const leagueUsersRequest = useApi(
        apiRoutes.GET_LEAGUE_USERS_BY_LEAGUE(leagueId.toString()),
        {
            responseKey: "leagueUsers",
            onSuccess: (response: LeaguesLeagueUser[]) => {
                setLeagueUsers(response);
            },
        }
    );

    const saveCommissioners = useApi(
        apiRoutes.SAVE_COMMISSIONERS(leagueId.toString()),
        {
            onSuccess: (message: string) => {
                toast.success(message);
                setSubmitting(false);
                setShowModal(false);
            },
            onFailure: () => setSubmitting(false),
            responseKey: "message",
        }
    );

    useEffect(() => {
        getCommissioners.request();
        leagueUsersRequest.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leagueId]);

    return (
        <ModalStyled
            show={showModal}
            onHide={() => setShowModal(false)}
            size="lg"
            className="text-center"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Manage Commissioner(s)</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Formik
                    initialValues={{}}
                    onSubmit={(values) => {
                        setSubmitting(true);
                        saveCommissioners.request({
                            league_user_ids: commissioners,
                        });
                    }}
                >
                    {({ handleSubmit }) => (
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                if (commissioners.length > 0) {
                                    handleSubmit(e);
                                } else {
                                    setError(true);
                                }
                            }}
                        >
                            <h5>Select commissioners:</h5>
                            <div className="text-left mb-4">
                                {leagueUsers.map((lu) => {
                                    return (
                                        <Row
                                            onClick={() => {
                                                if (
                                                    !commissioners.includes(
                                                        lu.id
                                                    )
                                                ) {
                                                    setCommissioners([
                                                        ...commissioners,
                                                        lu.id,
                                                    ]);
                                                    setError(false);
                                                } else {
                                                    const newCommissioners =
                                                        commissioners.filter(
                                                            (c) => c !== lu.id
                                                        );
                                                    setCommissioners(
                                                        newCommissioners
                                                    );
                                                }
                                            }}
                                            style={{ cursor: "pointer" }}
                                            className="ml-2 mb-4 align-items-center"
                                        >
                                            <Col xs={8} md={10}>
                                                <Row
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    className="ml-2 align-items-center"
                                                >
                                                    <Avatar
                                                        style={{
                                                            width: 30,
                                                            height: 30,
                                                            marginRight: 5,
                                                        }}
                                                        src={`${imageUrl}${lu.profile_pic}`}
                                                    />
                                                    {lu.first_name}{" "}
                                                    {lu.last_name} (
                                                    {lu.team_name})
                                                </Row>
                                            </Col>
                                            {commissioners.includes(lu.id) && (
                                                <Col
                                                    xs={4}
                                                    md={2}
                                                    className="text-center"
                                                >
                                                    <CheckCircleIcon color="success" />
                                                </Col>
                                            )}
                                        </Row>
                                    );
                                })}
                            </div>
                            {error === true && (
                                <div className="mb-3">
                                    <ErrorText>
                                        Please select at least one commissioner
                                    </ErrorText>
                                </div>
                            )}
                            <SubmitButton
                                disabled={submitting || error}
                                title="Save Commissioners"
                                submitting={submitting}
                                submitText="Saving"
                            />{" "}
                            <Button
                                variant="light"
                                onClick={() => setShowModal(false)}
                            >
                                Cancel
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </ModalStyled>
    );
};

export default ManageCommissioners;
