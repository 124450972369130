import styled from "styled-components";

export const SetLineupWrapper = styled.div`
    background-color: ${(props) => props.theme.primary};
    border-radius: 10px !important;
    width: 10px;
    height: 10px;
    position: absolute;
    right: -5px;
    bottom: -5px;
`;
