import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { CardStyled, PageHeader } from ".";
import { colors } from "../../config/colors";

export const LabelSkeleton = () => {
    return (
        <Skeleton animation="wave" variant="text" width="150px" height="40px" />
    );
};

export const InputSkeleton = () => {
    return <Skeleton animation="wave" variant="text" height="40px" />;
};

export const TextSkeleton = () => {
    return (
        <Skeleton animation="wave" variant="text" width="400px" height="40px" />
    );
};

export const TextAreaSkeleton = () => {
    return <Skeleton animation="wave" variant="rectangular" height="120px" />;
};

export const ButtonSkeleton = () => {
    return (
        <Skeleton
            className="mr-2"
            animation="wave"
            variant="text"
            height="50px"
            width="80px"
        />
    );
};

interface ButtonGroupProps {
    size: number;
}
export const ButtonGroupSkeleton: React.FC<ButtonGroupProps> = ({ size }) => {
    return (
        <Row className="m-0">
            {Array.from(Array(size), (e, i) => {
                return <ButtonSkeleton key={i} />;
            })}
        </Row>
    );
};

export const ArticleImageSkeleton = () => {
    return (
        <Skeleton
            animation="wave"
            variant="rectangular"
            width="100%"
            height="400px"
        />
    );
};

export const CastImageSkeleton = () => {
    return (
        <Skeleton
            className="mr-2"
            animation="wave"
            variant="circular"
            width={75}
            height={75}
        />
    );
};

export const ArticleTitleSkeleton = () => {
    return (
        <>
            <Skeleton
                animation="wave"
                variant="text"
                height="50px"
                width="40%"
            />
            <Skeleton
                animation="wave"
                variant="text"
                height="30px"
                width="20%"
            />
            <Skeleton
                animation="wave"
                variant="text"
                height="30px"
                width="30%"
            />
        </>
    );
};

export const ArticleTextSkeleton = () => {
    return (
        <div className="pt-3">
            <Skeleton animation="wave" variant="text" height="30px" />
            <Skeleton
                animation="wave"
                variant="text"
                width="70%"
                height="30px"
            />
            <Skeleton animation="wave" variant="text" height="30px" />
            <Skeleton
                animation="wave"
                variant="text"
                height="30px"
                width="30%"
            />
        </div>
    );
};

export const ArticleListSkeleton = () => {
    return (
        <div className="mb-4">
            <Row className="m-0">
                <Col md="6">
                    <ArticleImageSkeleton />
                </Col>
                <Col md="6">
                    <ArticleTitleSkeleton />
                    <ArticleTextSkeleton />
                </Col>
            </Row>
        </div>
    );
};

export const PageHeaderSkeleton = () => {
    return (
        <PageHeader>
            <Skeleton
                height="60px"
                width="40%"
                animation="wave"
                variant="text"
            />
        </PageHeader>
    );
};

export const LeaguesListTitleSkeleton = () => {
    return <Skeleton height="100px" animation="wave" variant="text" />;
};

export const LeaguesListItemSkeleton = () => {
    return (
        <Skeleton
            className="mb-2"
            animation="wave"
            variant="rectangular"
            height="125px"
            style={{ borderRadius: 20 }}
        />
    );
};

export const TableSkeleton = () => {
    return (
        <>
            <InputSkeleton />
            <InputSkeleton />
            <InputSkeleton />
            <InputSkeleton />
            <InputSkeleton />
            <InputSkeleton />
        </>
    );
};

export const CardSkeleton = () => {
    return (
        <Skeleton
            className="mb-4"
            animation="wave"
            variant="rectangular"
            height="363px"
            style={{ borderRadius: 20 }}
        />
    );
};

export const CastCardSkeleton = () => {
    return (
        <Skeleton
            className="mb-5"
            animation="wave"
            variant="rectangular"
            height="363px"
        />
    );
};

export const LargeImageSkeleton = () => {
    return (
        <Skeleton
            className="mb-5"
            animation="wave"
            variant="rectangular"
            height="350px"
        />
    );
};

export const ArticleSkeleton = () => {
    return (
        <>
            <LabelSkeleton />
            <ArticleImageSkeleton />
            <ArticleTextSkeleton />
            <ArticleTextSkeleton />
            <ArticleTextSkeleton />
        </>
    );
};

export const ScoreTeamListSkeleton = () => {
    return (
        <>
            <Row
                style={{
                    alignItems: "center",
                    marginLeft: 5,
                    marginBottom: 10,
                }}
            >
                <CastImageSkeleton />
                <LabelSkeleton />
            </Row>
            <Row
                style={{
                    alignItems: "center",
                    marginLeft: 5,
                    marginBottom: 10,
                }}
            >
                <CastImageSkeleton />
                <LabelSkeleton />
            </Row>
            <Row
                style={{
                    alignItems: "center",
                    marginLeft: 5,
                    marginBottom: 10,
                }}
            >
                <CastImageSkeleton />
                <LabelSkeleton />
            </Row>
        </>
    );
};

export const ScoreCardSkeleton = () => {
    return (
        <CardStyled className="mb-5">
            <Card.Header className="text-left">
                <LabelSkeleton />
            </Card.Header>
            <Card.Body className="flex-column text-left">
                <LabelSkeleton />
                <TextSkeleton />
                <TextSkeleton />
            </Card.Body>
            <Card.Footer className="text-left">
                <LabelSkeleton />
            </Card.Footer>
        </CardStyled>
    );
};

export const LiveScoreCardSkeleton = () => {
    return (
        <CardStyled className="mb-5">
            <Card.Header className="text-left">
                <Row className="ml-1 align-items-center">
                    <Skeleton className="mr-3" variant="circular">
                        <Avatar style={{ width: 75, height: 75 }} />
                    </Skeleton>
                    <LabelSkeleton />
                </Row>
            </Card.Header>
            <Card.Body className="flex-column text-left">
                <TextSkeleton />
            </Card.Body>
        </CardStyled>
    );
};

export const CommentSkeleton = () => {
    return (
        <Row className="mt-4">
            <Col md={1} xs={3}>
                <Skeleton variant="circular">
                    <Avatar />
                </Skeleton>
            </Col>
            <Col md={11} xs={9}>
                <Skeleton animation="wave" variant="text" height="30px" />
                <Skeleton
                    animation="wave"
                    variant="text"
                    height="30px"
                    width="30%"
                />
            </Col>
        </Row>
    );
};

export const ProfileSkeleton = () => {
    return (
        <>
            <LabelSkeleton />
            <TextSkeleton />
        </>
    );
};

export const ProfileImageSkeleton = () => {
    return (
        <div
            style={{
                borderRadius: 60,
                overflow: "hidden",
                width: 120,
                height: 120,
                backgroundColor: colors.borderLight,
                zIndex: 1000,
            }}
        ></div>
    );
};
