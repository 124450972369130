export const validGenderLimits = (
    male: null | number | string,
    female: null | number | string,
    total: number
) => {
    let maleLimit = male !== null && male !== undefined ? male : 0;
    let femaleLimit = female !== null && male !== undefined ? female : 0;

    if (maleLimit === "No Limit") {
        maleLimit = 0;
    }
    if (femaleLimit === "No Limit") {
        femaleLimit = 0;
    }

    if (
        parseInt(maleLimit.toString()) + parseInt(femaleLimit.toString()) <=
        parseInt(total.toString())
    ) {
        return true;
    } else {
        return false;
    }
};

export const validRookieLimit = (
    rookie: null | number | string,
    total: number
) => {
    let rookieLimit = rookie !== null && rookie !== undefined ? rookie : 0;

    if (rookieLimit === "No Limit") {
        rookieLimit = 0;
    }

    if (parseInt(rookieLimit.toString()) <= total) {
        return true;
    } else {
        return false;
    }
};
